import {
  GridColumn,
  GridRow,
  Heading,
  Icon,
  Interaction,
  LinkWithIcon,
  Spacing,
  Span,
} from 'r10-source-library';
import { isFetchingStoreBucket } from '../../selectors/selector';
import { useSelector } from 'react-redux';
import React from 'react';
import useTranslation from '../../hooks/useTranslation';

export default function RightSection(props) {
  const t = useTranslation();
  const isFetchingStoreBucketFlag = useSelector(isFetchingStoreBucket);

  return (
    <GridColumn col={12} colMd={6}>
      <GridRow>
        <Icon name="location" group="midLight" size={4} />
        <GridColumn>
          <div data-testid="right-section">
            <Spacing spacingLevel={{ left: 4 }}>
              <GridRow marginBottom={2}>
                <Heading
                  size={1}
                  weight={3}
                  dataAttributes={{ 'data-testid': 'right-section-title' }}
                >
                  {t('location')}
                </Heading>
              </GridRow>
              <GridRow>
                <Span weight={1} dataAttributes={{ 'data-testid': 'selected-store-id' }}>
                  {props.selectedStore?.id}
                </Span>
              </GridRow>
              <GridRow>
                <Span weight={1} dataAttributes={{ 'data-testid': 'selected-store-location' }}>
                  {props.selectedStore?.location}
                </Span>
              </GridRow>
              <GridRow>
                <Span weight={1} dataAttributes={{ 'data-testid': 'selected-store-phone-number' }}>
                  {props.selectedStore?.phoneNumber}
                </Span>
              </GridRow>

              {!!props.bucketAmount && (
                <GridRow>
                  <Spacing
                    spacingLevel={{
                      top: 3,
                      bottom: 3,
                    }}
                  >
                    <Span weight={2} dataAttributes={{ 'data-testid': 'store-bucket-title' }}>
                      {t('storeBucketAmount')}
                    </Span>
                    <Span weight={3} dataAttributes={{ 'data-testid': 'store-bucket-value' }}>
                      {props.bucketAmount}
                    </Span>
                  </Spacing>
                </GridRow>
              )}

              {props.showChangeLocation && (
                <GridRow>
                  <Interaction
                    onClick={props.openTray}
                    dataAttributes={{
                      'data-testid': 'change-location',
                    }}
                    disabled={isFetchingStoreBucketFlag}
                  >
                    <LinkWithIcon
                      appearance="primary"
                      inverse
                      text={t('changeLocation')}
                      icon={{
                        name: 'chevron-down',
                        justify: 'right',
                      }}
                      size={2}
                    />
                  </Interaction>
                </GridRow>
              )}
            </Spacing>
          </div>
        </GridColumn>
      </GridRow>
    </GridColumn>
  );
}
