import { pkgName } from '../appConstants';

export const getAgentInfo = state => state?.authentication?.agentTokenDetails?.user_info;
export const samAccountName = state => getAgentInfo(state)?.sub;

export const agentInfo = state => ({
  agentName: state[pkgName]?.agentName,
  primaryStoreId: state[pkgName]?.primaryStoreId,
  storesInfo: state[pkgName]?.storesInfo,
});

export const storeBucket = state => ({
  value: state[pkgName]?.storeBucket?.amount?.value,
  unit: state[pkgName]?.storeBucket?.amount?.unit,
});

export const storeDetails = state => state.props?.storeDetails;
export const storeId = state => state.props?.storeDetails?.storeId;
export const storeBucketUrl = state => state[pkgName]?.storeBucketUrl;
export const topupSuccessActionName = state => state[pkgName]?.topupActionName;

export const isFetching = state => state[pkgName]?.isFetching;
export const isFetchingStoreBucket = state => state[pkgName]?.isFetchingStoreBucket;
export const error = state => state[pkgName]?.error;
export const isRefreshToken = state => state?.agentlogin?.isRefreshToken;
