import {
  GET_AGENT_INFO_FAILURE,
  GET_AGENT_INFO_REQUEST,
  GET_AGENT_INFO_SUCCESS,
  GET_STORE_BUCKET_FAILURE,
  GET_STORE_BUCKET_REQUEST,
  GET_STORE_BUCKET_SUCCESS,
  SET_STORE_BUCKET_URL,
  SET_TOPUP_SUCCESS,
  SET_AGENT_CONFIG,
  RESET_AGENT_INFO,
} from '../actions';

import initialState from './initialState';

const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_AGENT_INFO: {
      return initialState;
    }
    case GET_AGENT_INFO_REQUEST: {
      return {
        ...state,
        storeBucket: '',
        isFetching: true,
        error: false,
      };
    }

    case GET_AGENT_INFO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    }

    case GET_AGENT_INFO_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    }

    case SET_STORE_BUCKET_URL: {
      return {
        ...state,
        storeBucketUrl: action.payload,
      };
    }

    case GET_STORE_BUCKET_REQUEST: {
      return {
        ...state,
        isFetchingStoreBucket: true,
      };
    }

    case GET_STORE_BUCKET_SUCCESS: {
      return {
        ...state,
        storeBucket: action.payload,
        isFetchingStoreBucket: false,
      };
    }

    case GET_STORE_BUCKET_FAILURE: {
      return {
        ...state,
        storeBucket: '',
        isFetchingStoreBucket: false,
      };
    }

    case SET_TOPUP_SUCCESS: {
      return {
        ...state,
        topupActionName: action.payload,
      };
    }

    case SET_AGENT_CONFIG: {
      return {
        ...state,
        orgUrl: action.payload.orgUrl,
        paths: action.payload.paths,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
