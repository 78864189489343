import {
  watchGetAgentInfo,
  watchGetStoreBucket,
  watchTokenSuccess,
  watchTopupSuccess,
  watchAgentInfoSuccess
} from './getAgentInfo';

const watchers = [
  watchGetAgentInfo(),
  watchGetStoreBucket(),
  watchTopupSuccess(),
  watchTokenSuccess(),
  watchAgentInfoSuccess()
];

export default watchers;
