import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setStoreBucketURL, setSuccessTopupActionName } from './actions';

import AgentBasicInfo from './components/AgentBasicInfo';
import { PropsProvider } from './context/PropsContext';
import { TranslationProvider } from './context/TranslationContext';
import reducer from './reducers';
import watchers from './sagas';

const Widget = ({ i18n, language, __instanceId, __pageId, ...props }) => {
  const dispatch = useDispatch();
  /** get Agent Data */
  useEffect(() => {
    if (props.storeBucketUrl) {
      dispatch(setStoreBucketURL(props.storeBucketUrl));
      dispatch(setSuccessTopupActionName(props.topupSuccessActionName));
    }
  }, []);

  const t = i18n
    ? key => {
        const tr = i18n(key);
        return tr || key;
      }
    : key => key;
  if (!language) return null;
  return (
    <TranslationProvider t={t}>
      <PropsProvider
        widgetProps={{
          __instanceId,
          __pageId,
          ...props
        }}
      >
        <AgentBasicInfo {...props} />
      </PropsProvider>
    </TranslationProvider>
  );
};

const mapStateToProps = ({ language: { language } }) => ({ language });

const exported = connect(mapStateToProps)(Widget);
exported.reducer = reducer;
exported.sagas = watchers;
export default exported;

const logger = store => next => action => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

exported.middlewares = [logger];
