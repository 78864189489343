const initialState = {
  isFetching: false,
  isFetchingStoreBucket: false,
  storeBucketUrl: '',
  storeBucket: '',
  topupActionName: '@topup/VALIDATE_NUMBER_SUCCESS',
  orgUrl: '',
  paths: null,
  error: false,
};
export default initialState;
